body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-head {
  height: 4.4em; }
  .site-head .right-elems .wrap {
    padding: 0.5rem;
    margin-left: 1.8rem;
    cursor: pointer;
    position: relative; }
    .site-head .right-elems .wrap .dropdown-item:active {
      background: #f5f5f5;
      color: #212121; }
  .site-head .right-elems .profile {
    padding: 0; }
    .site-head .right-elems .profile img {
      width: 36px;
      height: 36px;
      border-radius: 100%;
      border: 2px solid white; }
  .site-head .right-elems .notify .badge {
    font-size: 65%;
    position: absolute;
    top: -4px;
    right: -4px; }
  .site-head .site-search {
    position: relative; }
    .site-head .site-search input {
      font-size: 14px;
      border: none;
      border-radius: 1px;
      padding-left: 3.5rem;
      transition: 0.2s ease-in;
      padding-top: 0.9rem;
      padding-bottom: 0.9rem; }
    .site-head .site-search > svg {
      position: absolute;
      top: 0.8rem;
      left: 1rem; }
  .site-head .masjid-name {
    flex: 1 1;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #fff;
    word-break: break-all; }

@media (orientation: portrait) {
  .site-head {
    padding-top: 30px !important; } }

@media (orientation: portrait) {
  .view-header {
    padding-top: 30px !important; }
  .title {
    word-break: break-all; } }

.site-nav {
  border-right: 1px solid #eeeeee;
  width: 20em;
  position: fixed;
  z-index: 1030;
  top: 0;
  bottom: 0;
  transition: 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.05);
  background: #ffffff;
  -webkit-transform: translate(-100%, 0);
          transform: translate(-100%, 0); }
  .site-nav.mini {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  @media (min-width: 992px) {
    .site-nav {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0); }
      .site-nav.mini {
        -webkit-transform: translate(-95%, 0);
                transform: translate(-95%, 0); }
        .site-nav.mini:hover {
          -webkit-transform: translate(0, 0);
                  transform: translate(0, 0); } }
  .site-nav .nav-list-container {
    height: calc(100% - 4em); }
  .site-nav .scrollarea .scrollbar-container {
    opacity: 0; }
    .site-nav .scrollarea .scrollbar-container.vertical {
      width: 15px; }
  .site-nav .scrollarea .scrollbar-container:hover {
    background: transparent; }
  .site-nav .nav-head {
    border-bottom: 2px solid #D0E5E3;
    padding: 0.8rem 1rem;
    text-align: center;
    position: relative;
    min-height: 4.4em;
    margin-left: -1.5rem; }
  .site-nav .nav-head svg > path {
    stroke: #075e55; }
  .site-nav .nav-head .toggle-dot {
    width: 11px;
    height: 11px;
    border: 2px solid #075e55;
    border-radius: 50%;
    position: absolute;
    top: 0.8rem;
    right: 0.8rem;
    cursor: pointer; }
    .site-nav .nav-head .toggle-dot.active {
      background: #075e55; }
    .site-nav .nav-head .toggle-dot:before {
      content: '';
      position: relative;
      display: block;
      width: 400%;
      height: 400%;
      box-sizing: border-box;
      margin-left: -150%;
      margin-top: -150%;
      border-radius: 100%;
      background-color: #075e55;
      -webkit-animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
              animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite; }

@-webkit-keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33); }
  80%,
  100% {
    opacity: 0; } }

@keyframes pulse-ring {
  0% {
    -webkit-transform: scale(0.33);
            transform: scale(0.33); }
  80%,
  100% {
    opacity: 0; } }
  .site-nav .nav-head a {
    color: #212121; }
    .site-nav .nav-head a > img {
      height: 45px; }
  .site-nav .nav-list-title {
    padding: 0.4rem 1.6rem;
    margin: 1.4rem 0;
    font-size: 70%;
    text-transform: uppercase;
    font-weight: bold;
    position: relative; }
    .site-nav .nav-list-title:before {
      position: absolute;
      content: '';
      width: 15px;
      height: 2px;
      bottom: 0;
      background: #075e55; }
  .site-nav .nav-list li a {
    cursor: pointer;
    display: block;
    padding: 1.1rem 1.5rem;
    color: #353535;
    font-weight: 600;
    position: relative; }
    .site-nav .nav-list li a > .name {
      display: inline-block;
      margin-left: 1.5rem;
      text-transform: uppercase;
      font-size: 90%;
      -webkit-transform: translate(0, 1.1px);
              transform: translate(0, 1.1px); }
    .site-nav .nav-list li a > .icon-down {
      float: right;
      -webkit-transform: translate(0, 0.4rem);
              transform: translate(0, 0.4rem);
      transition: 0.15s ease-in; }
    .site-nav .nav-list li a:hover {
      color: #075e55; }
    .site-nav .nav-list li a .icon-dashboard {
      color: #075e55 !important; }
    .site-nav .nav-list li a .badge-chart.badge {
      background: #075e55; }
  .site-nav .nav-list li > a.active {
    font-weight: 700;
    color: #075e55; }
    .site-nav .nav-list li > a.active > .icon-down {
      -webkit-transform: translate(0, 0.4rem) rotate(90deg);
              transform: translate(0, 0.4rem) rotate(90deg); }
  .site-nav .nav-list li.selected > a {
    font-weight: 700;
    color: #075e55; }
    .site-nav .nav-list li.selected > a .icon-down {
      -webkit-transform: translate(0, 0.4rem) rotate(-270deg);
              transform: translate(0, 0.4rem) rotate(-270deg); }
  .site-nav .nav-list li .inner-drop {
    font-size: 90%;
    padding-left: 2.85rem;
    background: inherit;
    box-shadow: inset 0 1px 1px 0px rgba(0, 0, 0, 0.05), inset 0 -1px 1px 0 rgba(0, 0, 0, 0.05); }
  .site-nav .nav-list li .inner-drop a {
    padding: 0.9rem 1.5rem; }
  .site-nav .nav-list li .inner-drop a:hover {
    color: #075e55; }

.full-width {
  width: 100vw;
  -webkit-transform: translate(0, 0);
          transform: translate(0, 0);
  background-position: center;
  background-size: cover; }

.view .view-content.view-error {
  position: relative;
  padding: 0; }
  .view .view-content.view-error .display-404 {
    font-weight: 800;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12rem;
    margin-top: -8rem; }
  .view .view-content.view-error .display-404 > span {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #075e55;
    display: inline-block;
    position: relative; }
    .view .view-content.view-error .display-404 > span:before {
      content: '';
      position: absolute;
      width: 88%;
      height: 88%;
      background: #fff;
      border-radius: 100%;
      top: 6%;
      left: 6%; }


.view-content.view-session,
.view-content.view-invoice {
  position: relative; }
  .view-content.view-session:before,
  .view-content.view-invoice:before {
    content: '';
    position: absolute;
    background: #075e55;
    width: 100%;
    top: 0;
    left: 0;
    height: 40%; }
  .view-content.view-session .form-card, .view-content.view-session .form-card-register,
  .view-content.view-invoice .form-card,
  .view-content.view-invoice .form-card-register {
    width: 25rem; }
    .view-content.view-session .form-card svg > path, .view-content.view-session .form-card-register svg > path,
    .view-content.view-invoice .form-card svg > path,
    .view-content.view-invoice .form-card-register svg > path {
      stroke: #075e55; }
  @media (min-width: 992px) {
    .view-content.view-session .form-card, .view-content.view-session .form-card-register,
    .view-content.view-invoice .form-card,
    .view-content.view-invoice .form-card-register {
      width: 28rem; } }
  @media (min-width: 992px) {
    .view-content.view-session .form-card-register,
    .view-content.view-invoice .form-card-register {
      width: 35rem; } }
  @media (max-width: 354px) {
    .view-content.view-session:before,
    .view-content.view-invoice:before {
      position: fixed; } }

.social-auth li {
  display: inline-block; }

.social-auth li a {
  display: inline-block;
  padding: 0.8rem 0.9rem;
  border-radius: 4px;
  border: 1px solid;
  margin: 0 0.6rem;
  transition: 0.15s ease-in; }

.social-auth li:nth-child(1) a {
  background: #42a5f5;
  border-color: #42a5f5;
  color: #fff; }

.social-auth li:nth-child(2) a {
  background: #3949ab;
  border-color: #3949ab;
  color: #fff; }

.social-auth li:nth-child(3) a {
  background: #e53935;
  border-color: #e53935;
  color: #fff; }

.view .view-content.view-error {
  position: relative;
  padding: 0; }
  .view .view-content.view-error .display-404 {
    font-weight: 800;
    text-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
    font-size: 12rem;
    margin-top: -8rem; }
  .view .view-content.view-error .display-404 > span {
    width: 12rem;
    height: 12rem;
    border-radius: 100%;
    background: #075e55;
    display: inline-block;
    position: relative; }
    .view .view-content.view-error .display-404 > span:before {
      content: '';
      position: absolute;
      width: 88%;
      height: 88%;
      background: #fff;
      border-radius: 100%;
      top: 6%;
      left: 6%; }

.view .view-content.view-invoice .invoice-card {
  width: 25rem;
  min-width: 25rem;
  max-width: 58rem; }
  @media (min-width: 992px) {
    .view .view-content.view-invoice .invoice-card {
      width: 58rem; } }


section.pricing {
  /* fallback for old browsers */
  /* Chrome 10-25, Safari 5.1-6 */
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */ }

.pricing .card {
  border: none;
  border-radius: 1rem;
  transition: all 0.2s;
  box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1); }

.pricing hr {
  margin: 1.5rem 0; }

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold; }

.pricing .card-price {
  font-size: 2.5rem;
  margin: 0; }

.pricing .card-price .period {
  font-size: 0.8rem; }

.pricing ul li {
  margin-bottom: 1rem; }

.pricing .text-muted {
  opacity: 0.7; }

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s; }

/* Hover Effects on Card */
@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3); }
  .pricing .card:hover .btn {
    opacity: 1; } }

.plan-items {
  transition-duration: 0.3s;
  padding-left: 1.3rem; }

