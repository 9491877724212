section.pricing {
    // background: #9CECFB;
    /* fallback for old browsers */
    // background: -webkit-linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
    /* Chrome 10-25, Safari 5.1-6 */
    // background: linear-gradient(to right, #0052D4, #65C7F7, #9CECFB);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
}

.pricing hr {
    margin: 1.5rem 0;
}

.pricing .card-title {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
}

.pricing .card-price {
    font-size: 2.5rem;
    margin: 0;
}

.pricing .card-price .period {
    font-size: 0.8rem;
}

.pricing ul li {
    margin-bottom: 1rem;
}

.pricing .text-muted {
    opacity: 0.7;
}

.pricing .btn {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    padding: 1rem;
    opacity: 0.7;
    transition: all 0.2s;
}

/* Hover Effects on Card */

@media (min-width: 992px) {
    .pricing .card:hover {
        margin-top: -0.25rem;
        margin-bottom: 0.25rem;
        box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
        opacity: 1;
    }
}

.plan-items {
    transition-duration: 0.3s;
    padding-left: 1.3rem;
}
